/* You can add global styles to this file, and also import other style files */
@import url('colors.css');
@import url('tooltip.css');
@import '~swiper/swiper-bundle';
@import "~@angular/cdk/overlay-prebuilt.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~ngx-simple-modal/styles/simple-modal.css";

html {
  overflow-x: hidden;
}

/* Gallery */
.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

button {
  outline: none;
}


body {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h4 {
  width: 100%;
  font-size: 24pxm;
  line-height: 36px;
  text-align: center;
}

a.clickable-link {
  text-decoration: none;
}

a.clickable-link:hover {
  color: var(--accent-color);
  text-decoration: underline;
  cursor: pointer;
}

.noscroll {
  overflow-y: hidden;
}

.slanted-background-up {
  transform: skewY(-1deg);
  z-index: 10;
  top: -10%;
}

.slanted-background-down {
  transform: skewY(1deg);
  z-index: 10;
  bottom: -10%;
}

.slanted-content {
  z-index: 20;
  position: relative;
}

.card {
  border-radius: 8px;
}

.autopark-logo-dark {
  height: 100%;
  background-image: url('/assets/img/autoparkdark-logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.autopark-logo-light {
  height: 100%;
  background-image: url('/assets/img/autopark-logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.filter-background {
  background-image: url('/assets/img/e61c88f29ea4c1d3efd263ca06c979ca.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Colors */
.accent {
  color: var(--accent-color) !important;
}

.background-accent {
  background-color: var(--accent-color) !important;
}

.background-light-accent {
  background-color: #FBD2D3;
}

.gradient-accent {
  background: var(--accent-color);
  background: linear-gradient(90deg, #c8102e, #5e1521);
}


.primary {
  color: var(--primary-color);
}

.background-primary {
  background-color: var(--primary-color);
}


.secondary {
  color: var(--scondary-color);
}

.background-secondary {
  background-color: var(--scondary-color);
}


.dark {
  color: var(--dark-color) !important;
}

.background-dark {
  background-color: var(--dark-color);
}


.primary-gray {
  color: var(--primary-gray-color);
}

.background-primary-gray {
  background-color: var(--primary-gray-color);
}


.secondary-gray {
  color: var(--secondary-gray-color)
}

.background-secondary-gray {
  background-color: var(--secondary-gray-color)
}

.background-light-gray {
  background-color: var(--light-gray-color);
}

.background-overlay {
  background-color: rgba(0, 0, 0, 0.25);
}

/* Text */
.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-medium {
  font-weight: 500;
}

.medium {
  font-size: 1rem;
}

.medium-small {
  font-size: 0.75rem;
}


.tiny {
  font-size: 0.5em;
}

.lh-100 {
  line-height: 100%;
}

.strikethrough {
  text-decoration: line-through;
}

.lh-lg {
  line-height: 2;
}

.display-5 {
  font-size: 3rem;
  line-height: 3.25rem;
}

.display-6 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

/* Spacing */
.spacing-2 {
  margin-top: 12px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

/* List */
.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

.icon-list fa-icon {
  padding-right: 0.25rem;
}

.icon-list {
  font-size: 1rem;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1280px;
  }
}


.menu_icon {
  background: url("./assets/img/bars.svg") no-repeat;
  background-color: #fff;
  background-position: center;
  cursor: pointer;
  
  width: 26px;
  height: 26px;
  padding: 20px;
  display: block;
}

.menu_icon:hover {
  cursor: pointer;
}

.menu_icon svg {
  display: none;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.nopadding_horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 768px) {
  .no_vertical_padding_small {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.custom__button {
  font-weight: 600;
  letter-spacing: 1px;
  background-size: 200% auto;
  transition: all 0.25s ease;
  padding: 0 12px;
  cursor: pointer;
  background-image: linear-gradient(to right, #5e1521 0%, #c8102e 51%, #c8102e 100%);
}

.custom__button:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #c8102e 0%, #c8102e 51%, #5e1521 100%);
  background-position: right center;
}

.margin_auto {
  margin: 0 auto;
}


@media only screen and (max-width: 401px) {
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

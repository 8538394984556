.simple-tooltip {
    position:relative; /* making the .simple-tooltip span a container for the.simple-tooltip text */
  }

  .simple-tooltip:before {
    content: attr(data-text); /* here's the magic */
    position:absolute;
    
    /* vertically center */
    top:50%;
    transform:translateY(-50%);
    
    /* move to right */
    left:100%;
    margin-left:15px; /* and add a small left margin */
    
    /* basic styles */
    width:200px;
    padding:10px;
    border-radius:10px;
    background:#000;
    color: #fff;
    text-align:center;
  
    opacity: 0;
    transition: .3s opacity;   
  }

  .simple-tooltip:hover:before {
    opacity: 1;
  }

  .simple-tooltip.left:before {
    /* reset defaults */
    left:initial;
    margin:initial;
  
    /* set new values */
    right:100%;
    margin-right:15px;
  }

  .simple-tooltip:after {
    content: "";
    position:absolute;
    
    /* position.simple-tooltip correctly */
    left:100%;
    margin-left:-5px;
   
    /* vertically center */
    top:50%;
    transform:translateY(-50%);
   
    /* the arrow */
    border:10px solid #000;
    border-color: transparent black transparent transparent;
    
    opacity:0;
    transition:.3s;
  }
  .simple-tooltip:hover:before, .simple-tooltip:hover:after {
    opacity:1;
  }